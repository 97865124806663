<template>
  <div class="background">
    <b-row class="no-gutters mx-auto justify-content-center p_y__3">
      <b-col class="col-12 col-md-8 col-lg-6 col-xl-4 p-1">
        <div style="wdith: 100%; text-align: center">
          <img
            style="width: 250px"
            src="../assets/menu-icons/MENU_logo.png"
            alt="Image"
            class="rounded-0"
          />
        </div>
        <b-card no-body class="pt-2 px-3 pb-4 border-0 bg-transparent">
          <!-- <h6 class="mb-0 text-red font-weight-bold text-uppercase">
            {{ $t("welcome") }}
          </h6>
          <hr class="my-2 mx-n3" /> -->
          <b-row class="no-gutters mt-4">
            <!-- <b-col class="col-12 col-sm-auto">
              <img
                style="width: 100px"
                src="../assets/images/Emotiva-logo-dash.png"
                alt="Image"
                class="rounded-0"
              />
            </b-col> -->
            <b-col class="col-12 col-sm">
              <b-card-body class="pt-4 pt-sm-3 pl-sm-3 px-0 pb-1">
                <h1 class="text-uppercase title__1">
                  {{ $t("page.passwordRecovery.forgotPasswordTitle") }}
                </h1>
                <p class="body__2 text-muted">
                  {{ $t("page.passwordRecovery.forgotPasswordDescription") }}
                </p>
                <validation-observer v-slot="{ valid }">
                  <b-form-group
                    label-class="font-weight-bold"
                    :label="$t('email')"
                    label-for="email"
                  >
                    <validation-provider
                      :name="$t('email')"
                      :rules="{ required: true, email: true }"
                      v-slot="validationContext"
                    >
                      <b-form-input
                        id="email"
                        class="input__field"
                        v-model="email"
                        :state="getValidationState(validationContext)"
                        @input="email = email.toLowerCase()"
                      ></b-form-input>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                  <b-row class="no-gutters justify-content-between mt-5">
                    <b-col class="col-12 col-sm-auto order-1 order-sm-0">
                      <b-button
                      variant="none"
                      style="background-color: grey"
                        class="rounded-btn"
                        @click="goToLoginPage"
                      >
                        {{ $t("button.backToLogin") }}
                      </b-button>
                    </b-col>
                    <b-col
                      class="col-12 col-sm-auto mb-3 mb-sm-0 order-0 order-sm-1"
                    >
                      <b-button
                      style="background-color: var(--emotiva-pink);"
                        class="text-uppercase rounded-btn"
                        :disabled="!valid"
                        @click="passwordRecovery"
                      >
                        {{ $t("button.send") }}
                      </b-button>
                    </b-col>
                  </b-row>
                </validation-observer>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: ""
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null, untouched }) {
      if (!untouched) return valid ? null : valid;
      else return null;
    },
    goToLoginPage() {
      this.$router.push({ name: "Login" });
    },
    passwordRecovery() {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("auth/recoverUserPassword", {
          email: this.email
        })
        .then(() => {
          this.$store.commit("success/SET_SUCCESS_INFO", {
            titleText: "dialog.success.passwordRecovery.title",
            messageText: "dialog.success.passwordRecovery.message"
          });
        })
        .catch(() => {})
        .finally(() => this.$store.dispatch("loader/close"));
    }
  }
};
</script>

<style scoped>
.background {
  /* background: url("../assets/images/Login-page-network.png"); */
  background-size: 500px;
  background-repeat: no-repeat;
  background-position: right 0 top 0;
}
.rounded-btn{
  
  color:#fff;
  padding: 15px;
  border-radius: 28px;
  min-width: 109px;
  min-height: 56px;
}
</style>
